import React, {useEffect, useState} from 'react';
import {
    useParams
} from "react-router-dom";
import {dataService} from "../services/getConstructorContent.service";

import Layout from '../components/Shared/Layout'
import AdvantagesBlock from '../components/Constructor/AdvantagesBlock/AdvantagesBlock'
import useLoading from "../hooks/useLoading"
import TextBlock from "../components/Constructor/TextBlock/TextBlock";
import CardBlock from "../components/Constructor/CardBlock/CardBlock";
import InfoBlock from "../components/Constructor/InfoBlock/InfoBlock";
import CalculatorBlock from "../components/Constructor/CalculatorBlock/CalculatorBlock";
import Tabs from "../components/Constructor/Tabs/Tabs";
import SpinnerLoader from "../components/Shared/spinnerLoader";


export const ContentLogic = ({blocks})=>{
    let left = null;

    return(
        <div>
            {
                blocks.map(item=>{
                    if(item!==null){
                        switch (item.num_block_id) {
                            case 1:return  <AdvantagesBlock parameters={item}/>
                            case 2: {
                                if(item.type==='3'||item.type==='4'){
                                    if(left){
                                        const leftContent = left;
                                        left = null;
                                        return <CardBlock left={leftContent} right={item}/>
                                    }
                                    else {
                                        left=item;
                                        break;
                                    }
                                }else return <CardBlock parameters={item}/>

                            }
                            case 3:
                                return <InfoBlock parameters={item}/>
                            case 4:
                                return <TextBlock parameters={item}/>
                            case 5:
                                return <CalculatorBlock parameters={item}/>
                            case 6:
                                return <Tabs paramters={item}/>
                            default:
                                return <span></span>
                        }
                    }
                })
            }
        </div>
    )}


const Constructor = () => {
    const [blocks, setBlocks]= useState([]);
    const {isLoading} = useLoading();

    const {id} = useParams();

    useEffect(() => {
        dataService.getContent(id).then(data => {
            setBlocks(data.data.blocks.sort(function(a, b) {
                return a.order_id - b.order_id;
            }));
            }
        );
    }, [id])



    return(
        <Layout>
            {
                isLoading?<ContentLogic blocks={blocks}/>: <SpinnerLoader />
            }

        </Layout>
    )

};

export default Constructor;
