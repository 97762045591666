import React, {useEffect, useState} from 'react';
import AOS from "aos";
import styled from "styled-components";
import {Button} from "../UI/CommonClasses";
import {dataService} from "../../services/getConstructorContent.service";
import useModule from "../../hooks/useModule";
import {useTranslation} from "react-i18next";
import successLogo from "../../img/success_icon.png"
import errorLogo from "../../img/error_icon.png"
import {useParams} from "react-router-dom";
import {InputMask, useMask} from "@react-input/mask";


const Wrapper = styled.div`
  z-index: 100000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 130px;
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000059;
`;

const Content = styled.div`
  width: 448px;
  //justify-content: space-between;
  display: flex;
  color: #FCFCFD;
  flex-direction: column;
  background: #F7F9FE;
  border-radius: 24px;
  padding: 32px;
  //align-items: center;
  
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin: 32px 32px 32px 32px;
    padding: 32px 24px 32px 24px;
    height: unset;
  }
`;

const Title = styled.div`
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;

  letter-spacing: -0.02em;
  color: #03153D;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.01em;
  }
`
const CloseBtn = styled.span`
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #E6E8EC;
  width: 32px;
  height: 32px;
  align-self: center;
  padding: 8px;
  font-size: 14px;
  box-sizing: border-box;
  margin-left: auto;
`

const Subtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #353945;
  padding-bottom: 10px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 14px;
  }

`;

const Text = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #B1B5C4;
  padding-bottom: 12px;
  text-transform: uppercase;
`

const Input = styled.input`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 2px solid #E6E8EC;
  border-radius: 12px;
  margin-bottom: 24px;
`

const Img = styled.img`
  width: 80px;
  height: 80px;
  margin: 0 auto;
  margin-bottom: 40px;
`;

const AgreementWindow = (props)=>{
    const { t } = useTranslation();

    const {isModuleOpen,setModule} = useModule();
    const [data,setData]=useState({full_name:'',phone:'', email:'',type:isModuleOpen.text});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const send=()=> {
        const isMortgage = isModuleOpen.text === 'Ипотека алуу' || isModuleOpen.text === 'Получить ипотеку' || isModuleOpen.text === 'Get mortgage';
        if(isMortgage && !data.amount || data.amount < 250000) data.amount = 250000;
        dataService.postRequest(data, id, isMortgage).then(data=> {
            setSuccess(data.success);
            setError(false);
        }).catch(error => {
            setSuccess(false);
            setError(true);
        });
    }
    useEffect(() => {
        AOS.init({duration: 1500});
        AOS.refresh();
    }, []);
    const currentLang = localStorage.getItem('currentLang') || 'ru';
    const getText = (key) => {
      const obj = {
          ru: {
              title: 'Как вас зовут?',
              phoneTitle: 'Номер телефона',
              amountTitle: 'Сумма',
              submit: 'Отправить',
              cancel: 'Отмена',
          },
          en: {
              title: 'WHAT IS YOUR NAME?',
              phoneTitle: 'PHONE NUMBER',
              amountTitle: 'Amount',
              submit: 'Send',
              cancel: 'Cancel',
          },
          kg: {
              title: 'АТЫҢЫЗ КИМ?',
              phoneTitle: 'ТЕЛЕФОН НОМЕРИ',
              amountTitle: 'Сум',
              submit: 'Жөнөтүү',
              cancel: 'Жокко чыгаруу',
          },
      }
      return obj[currentLang][key];
    };

    const {id} =useParams();

    const inputRef = useMask({
        mask: '+996 (___) ___ ___',
        replacement: { _: /\d/ },
        showMask: true,
    });

    return(
        <Wrapper data-aos='zoom-out' data-aos-duration="500">
            <Content>
                <Title>
                    {!success && !error && <span>{isModuleOpen.text}</span>}
                    <CloseBtn onClick={()=>setModule({open:false})}>&#x2715;</CloseBtn>
                </Title>
                {success && <>
                    <Img src={successLogo}/>
                    <Title>
                        {t('Your application is accepted')}
                    </Title>
                    <Subtitle>
                        <span>{t('Our specialists will contact you shortly')}</span>
                    </Subtitle>
                    </>
                }
                {error && <>
                    <Img src={errorLogo}/>
                    <Title>
                        {t('Some error happened!')}
                    </Title>
                    <Subtitle>
                        <span>{t(`We're sorry, seems like something went wrong. Please, call to contact center or try again.`)}</span>
                    </Subtitle>
                    </>
                }
                {!success && !error && <>
                    <Text>{getText('title')}</Text>
                    <Input onChange={(event)=>setData({...data,full_name:event.target.value})}></Input>
                    <Text>{getText('phoneTitle')}</Text>
                    <Input ref={inputRef} type="tel" onChange={(event)=>setData({...data,phone:event.target.value})}></Input>
                    {(id === '3' || (id === '106' && (isModuleOpen.text === 'Ипотека алуу' || isModuleOpen.text === 'Получить ипотеку' || isModuleOpen.text === 'Get mortgage'))) && <>
                        <Text>{getText('amountTitle')}</Text>
                        <Input type="number" onChange={(event)=>setData({...data,amount:event.target.value})}></Input>
                        </>
                    }
                    <div style={{margin:'10px 0' , width:'100%'}}/>
                    <Button onClick={()=>send()}>{getText('submit')}</Button>
                    <div style={{margin:'10px 0', width:'100%'}}/>
                    <Button onClick={()=>setModule({open:false})}>{getText('cancel')}</Button>
                    </>
                }
                {(success || error) && <>
                    <div style={{margin:'10px 0' , width:'100%'}}/>
                    <Button onClick={()=>setModule({open:false})}>OK</Button>
                </>}
            </Content>
        </Wrapper>
    )
}

export default AgreementWindow
